import React from 'react';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';

import defaultImage from '../default-wp-image.jpg';

const FluidImage = ({ image, alt, ...rest }) => {
  if (image) {
    return (
      <GatsbyImage fluid={image.childImageSharp.fluid} alt={alt} {...rest} />
    );
  }

  return <img src={defaultImage} alt="Kabbage logo" {...rest} />;
};

FluidImage.propTypes = {
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        tracedSVG: PropTypes.string,
        aspectRatio: PropTypes.number,
        sizes: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string,
      }),
    }),
  }),
  alt: PropTypes.string,
};

FluidImage.defaultProps = {
  image: null,
  alt: '',
};

export default FluidImage;
